// utils
import { GetServerSideProps } from 'next';
import { getSession } from 'next-auth/react';
// components
import { Home } from '@/components/questionnaire/Home/Home';
// layouts
import MainLayout from '@/layouts/main/MainLayout';
import { getServerSideTranslations } from '@/utils/i18n';
import { getServerSideDevCycle } from '@devcycle/nextjs-sdk/pages';

const Page = () => {
  return <Home />;
};

Page.getLayout = (page: React.ReactElement) => (
  <MainLayout
    className="main-layout"
    sx={{ padding: 0 }}
  >
    {page}
  </MainLayout>
);

export default Page;

export const getServerSideProps: GetServerSideProps = async context => {
  const session = await getSession(context);
  try {
    return {
      props: {
        ...(await getServerSideTranslations(context.locale, [
          'questionnaire',
        ])),
        ...(await getServerSideDevCycle({
          serverSDKKey: process.env.DEVCYCLE_SERVER_SDK_KEY ?? '',
          clientSDKKey: process.env.NEXT_PUBLIC_DEVCYCLE_CLIENT_SDK_KEY ?? '',
          user: {
            user_id: session?.user?.id || null,
            email: session?.user?.email || null,
            customData: {
              organisationId: session?.user?.extension_OrganisationId || null,
            },
          },
          context,
        })),
      },
    };
  } catch {
    return { notFound: true };
  }
};
